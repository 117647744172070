<template>
  <div class="swap-index">
    <subnav currPage="tokenSwap" showPrevious></subnav>
    <v-container class="my-5 pa-sm-7">
      <!-- title -->
      <div class="font-w-4 d-flex justify-space-between align-center mb-2 mb-sm-15">
        <span>
          <span class="rem-3 rem-sm-8 lightPrimary2--text mr-2">EGT - ETH</span><span class="rem-0 rem-sm-3 swap-text">SWAP</span>
        </span>
        <!--<div class="rem-0 rem-sm-3 swap-text d-flex align-center">
          TBT SWAP
          <img class="ml-1" src="@/assets/img/icon-to-swap.svg">
        </div>-->
      </div>

      <!-- card -->
      <div class="swap-card rounded-lg pa-6 pa-sm-0">
        <!-- From -->
        <div class="swap-text mb-3">From</div>
        <v-form class="swap-input" ref="swapForm" lazy-validation>
          <v-text-field
            v-model="fromAmount"
            class="rounded-lg"
            filled
            flat
            solo
            reverse
            background-color="#1A1C29"
            :placeholder="swapTokens[currToken] === 'eth' ? `0 ETH` : `0.000,000,000 ${swapTokens[currToken].toUpperCase()}`"
            persistent-hint
            :hint="`Balance ${balance[swapTokens[currToken]]}`"
            :rules="[...TokenAmountRules, (v) => parseFloat(v) < balance[swapTokens[currToken]] || 'Under Balance']"
            @change="update('from')"
          >
            <template v-slot:append>
              <div class="swap-token">
                <img :src="`${require(`@/assets/img/icon-logo-${swapTokens[currToken]}.svg`)}`" :alt="swapTokens[currToken].toUpperCase()" width="23px">
                {{ swapTokens[currToken].toUpperCase() }}
              </div>
            </template>
          </v-text-field>
          <div class="input-hint can-click" @click="fromAmount = balance[swapTokens[currToken]]">MAX</div>
        </v-form>

        <!-- convert -->
        <div class="convert-btn py-1 mt-4 mb-1 mx-auto font-w-4 rem-0 lightPrimary2--text d-flex justify-center align-center can-click" @click="convert()">
          <img src="@/assets/img/icon-convert.svg" class="mr-1">
          Convert
        </div>

        <!-- To -->
        <div class="swap-text mb-3">To</div>
        <div class="swap-input">
          <v-text-field
            v-model="toAmount"
            class="rounded-lg"
            filled
            flat
            solo
            reverse
            background-color="#1A1C29"
            :placeholder="swapTokens[1 - currToken] === 'eth' ? `0 ETH` : `0.000,000,000 ${swapTokens[1 - currToken].toUpperCase()}`"
            persistent-hint
            :hint="`Balance ${balance[swapTokens[1 - currToken]]}`"
            @change="update('to')"
          >
            <template v-slot:append>
              <div class="swap-token">
                <img :src="`${require(`@/assets/img/icon-logo-${swapTokens[1 - currToken]}.svg`)}`" :alt="swapTokens[1 - currToken].toUpperCase()" width="23px">
                {{ swapTokens[1 - currToken].toUpperCase() }}
              </div>
            </template>
          </v-text-field>
          <div class="input-hint can-click" @click="toAmount = balance[swapTokens[1 - currToken]]">MAX</div>
        </div>

        <v-btn v-if="allowance[swapTokens[currToken]]===0 || allowance[swapTokens[currToken]]<balance[swapTokens[currToken]]" color="#C93F3F" width="100%" height="38" class="rem-6 mb-2" light depressed @click="approve()">{{ $t('approve') }}</v-btn>
        <div v-else class="swap-btn mt-8 mb-3 mx-auto py-2 lightPrimary2 black--text font-w-4 rem-0 text-center can-click" @click="swap()">SWAP</div>
        <div class="font-w-4 rem-0 swap-text text-center">{{ swapTokens[currToken] === 'egt' ? '1 ETH：1200 EGT' : '1 ETH：1000 EGT' }}</div>
      </div>
    </v-container>
  </div>
</template>
<script>
import subnav from '@/components/subnav.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data(){
    return {
      swapTokens: ['egt', 'eth'],
      currToken: 0,
      balance: {
        egt: 0,
        eth: 0,
      },
      allowance: {
        egt: 0,
        eth: 0,
      },
      fromAmount: null,
      toAmount: null,
      ethPrice: 0,
      timer: null,
    }
  },
  components: {
    subnav
  },
  methods: {
    convert() {
      this.currToken = 1 - this.currToken
      this.fromAmount = null
      this.toAmount = null
    },
    update(type) {
      if (type === 'from') {
        let price = this.swapTokens[this.currToken] === 'eth' ? 1000 : 1200
        this.toAmount = this.swapTokens[this.currToken] === 'eth' ? parseFloat(this.fromAmount) * price : (parseFloat(this.fromAmount) / price).toFixed(5)
      } else {
        let price = this.swapTokens[this.currToken] === 'eth' ? 1000 : 1200
        this.fromAmount = this.swapTokens[this.currToken] === 'eth' ? (parseFloat(this.toAmount) / price).toFixed(5) : parseFloat(this.toAmount) * price
      }
    },
    async getBalance() {
      this.balance.egt = await this.$token.egt.getBalance()
      this.balance.eth = await this.$token.eth.getBalance()
    },
    async getAllowance() {
      this.allowance.egt = await this.$token.egt.getAllowance(this.$store.state.exchangeAddress)
      this.allowance.eth = await this.$token.eth.getAllowance(this.$store.state.exchangeAddress)
    },
    async approve() {
      let token = this.swapTokens[this.currToken]
      if (this.$store.state.chainId){
        let result = await this.$token[token].approve(this.$store.state.exchangeAddress)
        // console.log('result', result)
        if (result.txHash){
          this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitApprove')})
          this.timer = window.setInterval(async () => {
            await this.getAllowance()
            if (this.allowance[token] >= this.balance[token]) {
              window.clearInterval(this.timer)
              this.$store.commit('updateLoading', {isShow: false, text: ''})
            }
          }, 1000)
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async swap() {
      if (this.$refs.swapForm.validate()) {
        if (this.$store.state.chainId){
          let result
          if (this.swapTokens[this.currToken] === 'eth') {
            result = await this.$exchange.buy(this.fromAmount)
          } else {
            result = await this.$exchange.sell(this.fromAmount)
          }
          
          // console.log('result', result)
          if (result.txHash){
            await this.getBalance()
            this.$toasted.show(this.$t('txSend'))
            this.fromAmount = null
            this.toAmount = null
            this.$refs.swapForm.reset()
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }else{
          this.$toasted.error(this.$t('changeMainnet'))
        }
      }
    }
  },
  async mounted(){
    let result = await this.$store.dispatch('getEthPrice')
    if (result.status === 200) {
      this.ethPrice = result.data
    }

    await this.getBalance()
    await this.getAllowance()
  }
}
</script>
<style lang="scss">
.swap-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
    background: linear-gradient(93.36deg, rgba(99, 99, 99, 0.7) 1.1%, rgba(54, 54, 54, 0.7) 96.27%);
    box-shadow: 0px 4.7px 4.7px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3.5px);
    border-radius: 6px;
  }
  .swap-card {
    background: linear-gradient(210.87deg, #323849 1.32%, #1C1D2C 96.63%);
    @include dai_vuetify_sm_min {
      background: transparent;
    }
    
    .swap-token {
      background: #33394A;
      padding: 8px;
      border-radius: 4px;
      color: var(--v-grey-base);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 11px;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
      }
    }
    .swap-input {
      position: relative;
      .input-hint {
        position: absolute;
        font-size: 12px;
        right: 0;
        bottom: 6px;
        font-weight: 700;
        color: var(--v-lightGrey1-base);
        @include dai_vuetify_sm {
          color: var(--v-grey-base);
        }
      }
      .v-messages {
        font-weight: 400;
        color: var(--v-lightGrey1-base);
        @include dai_vuetify_sm {
          color: var(--v-grey-base);
        }
      }
    }
    .convert-btn {
      background: #1A1C29;
      border-radius: 32px;
      width: 100px;
    }
    .swap-btn {
      width: 180px;
      border-radius: 32px;
    }
  }
  .swap-text {
    color: var(--v-lightGrey1-base);
    @include dai_vuetify_sm {
      color: var(--v-grey-base);
    }
  }
}
</style>